





import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'SuccessStep',
  components: {
    BasePictureWithText: () => import('./BasePictureWithText.vue'),
  },
})
export default class SuccessStep extends Vue {
  @Prop({
    type: String,
  })
  readonly label!: string
}
